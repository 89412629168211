import {
  Children,
  isValidElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_TAB_EVENT } from '../../constants/feeds';
import Nav from './Nav';
import Content from './Content';
import styles from './ContentSwitcher.module.scss';
import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';

/**
 * Component facilitates the rendering and navigation of a dynamic list of child components with
 * optional CSS transitions. It renders the first child component along with a navigation element
 * for switching between components.
 *
 * @component
 * @param {boolean} [props.unmountOnTransitionEnd=false] - If true delays unmounting until after CSS transitions complete.
 * @param {ReactNode} props.children - The child components to be switched.
 * @param {string} [props.id='ContentSwitcher'] - The unique identifier for the ContentSwitcher
 * component. Also used as the idprefix for subcomponents.
 * @param {string} [props.navElementProps={}] - Additional properties to incluide on the `<nav>` element.
 * @returns {ReactNode} The rendered ContentSwitcher component.
 */
const ContentSwitcher = ({
  children,
  id = 'ContentSwitcher',
  navElementProps = {},
  unmountOnTransitionEnd = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(null);
  const [isTransitionApplied, applyTransition] = useState(false);
  const [isContentIntersecting, setIsContentIntersecting] = useState(false);
  const navRef = useRef(null);
  const contentListRef = useRef(null);
  // CSS variable to store the top offset value applied to the content that will be transitioning
  // out of view.
  const transitionOffsetTop = useRef('0px');

  // Create a list containing configuration for each provided child (<Content>).
  const contentList = useMemo(() => {
    // Configuration object for each child component
    return Children.map(
      Children.toArray(children),
      (child, index) => isValidElement(child) && {
        children: child.props.children,
        className: child.props.className,
        Component: child.type,
        ariaLabledByNav: child.props.ariaLabledByNav,
        index,
        /**
         * Optional `label` prop used for navigation, displayed in the navigation element to
         * identify and switch between components.
         */
        label: child.props.label,
        navTrackingData: child.props.navTrackingData,
        name: child.props.name,
      }
    ).filter(Boolean);
  }, [children]);

  /**
   * Calculates the scroll and transition offset values based on the position of sticky elements.
   * @returns {Object} Object containing window.scrollTo and content transition offset values.
   */

  const getOffsets = useMemo(() => () => {
      const contentListOffsetTop = window.scrollY + contentListRef.current.getBoundingClientRect().top;
      const topOffset = stickyRegistry.getAvailableTop(contentListRef.current);

      return {
        // Used to position the content that will be transitioning out of view.
        transitioningContent: (window.scrollY - contentListOffsetTop + topOffset) * -1,
        /**
         * If the nav is fixed to the bottom of the viewport, we should scroll to the top of the content list
         * (offset.scroll).
         * Otherwise, we should scroll to the top of the content list MINUS
         * the feed tab bottom offset. This is to ensure the content list is not hidden behind the
         * nav.
         */
        scroll: contentListOffsetTop - topOffset,
      }
    }, [contentListRef, stickyRegistry]);


  const onNavClick = useMemo(() => (index, { skipTransition = false } = {}) => {
    if (!isTransitionApplied && typeof index === 'number' && index !== activeIndex) {
      if (!skipTransition && unmountOnTransitionEnd) {
        setNextIndex(index);
      } else {
        setActiveIndex(index);
      }
    }
    /**
     * If the index is the same as the active index, nothing should occur besides scrolling back up
     * to the top of the content list.
     */
    else {
      const offset = getOffsets();
      if (typeof offset.scroll === 'number') {
        window.scrollTo({ top: offset.scroll, behavior: 'smooth' });
      }
    }
  }, [activeIndex, isTransitionApplied, unmountOnTransitionEnd, getOffsets]);

  /**
   * Observe the content list to determine if it is intersecting with the viewport. This is used to
   * determine if the content is scrolled past the top of the viewport.
   */
  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        setIsContentIntersecting(entry.isIntersecting);
      });
    };

    const io = new IntersectionObserver(observerCallback);
    io.observe(contentListRef.current, { threshold: 1.0 });

    return () => {
      io.unobserve(contentListRef.current);
    }
  }, [contentListRef, navRef]);

  /**
   * Ensure a smooth transition by applying the transition classname and properties after the
   * nextIndex state is rendered, preventing 'display: none' transition issues by ensuring the
   * element is visible before transitioning.
   */
  useEffect(() => {
    if (typeof nextIndex === 'number') {
      const offset = getOffsets();
      let behavior = 'smooth';

      // If the content is already scrolled past the top of the viewport, transition instantly
      if (contentListRef.current.getBoundingClientRect().top <= 0) {
        transitionOffsetTop.current = `${offset.transitioningContent}px`;
        behavior = 'instant';
      } else {
        transitionOffsetTop.current = '0px';
      }

      if (typeof offset.scroll === 'number') {
        window.scrollTo({
          behavior,
          top: offset.scroll,
        });
      }

      applyTransition(true);
    }
  }, [getOffsets, nextIndex]);

  const onTransitionEnd = useMemo(() => event => {
    if (
      event.target === event.currentTarget ||
      event.target?.dataset?.contentTransitionStatus === 'next'
    ) {
      // Update active index and reset transition state when transition ends
      setActiveIndex(nextIndex);
      setNextIndex(null);
      applyTransition(false);
      // Clears CSS variables that have been set on the content container to aid with transitions.
      transitionOffsetTop.current = '0px';
    }
  }, [nextIndex]);

  const classNameList = useMemo(
    () => [
      styles.contentList,
      isTransitionApplied && styles.transitionPending,
      /**
       *  Determine if the next index is greater (1) or less (-1) then the current index. The value
       *  is applied as a css variable and can be used in calculating the direction of a transition
       *  based on the direction of the navigation.
       */
      (nextIndex || 0) - activeIndex > 0 ? styles.transitionRight : styles.transitionLeft,
    ].filter(Boolean).join(' '),
    [activeIndex, nextIndex, isTransitionApplied]
  );

  /**
   * Callback function to set the active tab when the ACTIVE_TAB_EVENT is dispatched.
   * @todo
   * This function will scroll down to the feed tab content but before doing so, switches the feed
   * tab content to a new active tab with no transition applied. This is to prevent impressions from
   * firing on the wrong tab. We may want to consider an intersection observer that prevents
   * transitions if the content is off screen for all scenarios.
   * @param {Event} event - The event object dispatched by the window.
   */
  const setActiveTab = useMemo(() => (event) => {
    const { detail } = event;
    const index = contentList.findIndex((element) => element.children.props.data.name === detail);
    const tabIsNotActive = index !== -1 && index !== activeIndex;

    if (tabIsNotActive) {
      // prevent the event listener being added multiple times
      window.removeEventListener(ACTIVE_TAB_EVENT, setActiveTab);
      // Skipping transition when clicking
      onNavClick(index, { skipTransition: true });
    }

    const offset = getOffsets();
    if (typeof offset.scroll === 'number') {
      window.scrollTo({ top: offset.scroll, behavior: 'smooth' });
    }
  }, [navRef, activeIndex, contentList, getOffsets]);

  // Listen for the active tab event to switch to the tab
  useEffect(() => {
    window.addEventListener(ACTIVE_TAB_EVENT, setActiveTab);
    return () => {
      window.removeEventListener(ACTIVE_TAB_EVENT, setActiveTab);
    };
  }, [setActiveTab]);

  useEffect(() => {
    if (!navRef.current) return;
    const navPositionTop = stickyRegistry.getAvailableTop(navRef.current);
    const navHeight = navRef.current.offsetHeight;
    document.documentElement.style.setProperty('--sticky-elements-offset-top', `${navPositionTop}px`);
    document.documentElement.style.setProperty('--fixed-elements-offset-bottom', `${navHeight}px`);
    stickyRegistry.add(navRef.current);
    return () => {
      stickyRegistry.remove(navRef.current);
    };
  }, [stickyRegistry, navRef]);

  // When there is only one item, there would be no where to navigate to
  const showNav = contentList.length > 1;
  return (
    <>
      {showNav &&
        <Nav
          activeIndex={activeIndex}
          className={navElementProps.className}
          contentList={contentList}
          idPrefix={id}
          isContentIntersecting={isContentIntersecting}
          nextIndex={nextIndex}
          onClickCallback={onNavClick}
          style={navElementProps.style}
          navRef={navRef}
        />
      }

      <ul
        className={classNameList}
        onTransitionEnd={unmountOnTransitionEnd ? onTransitionEnd : undefined}
        ref={contentListRef}
        style={{
          '--content-scroll-offset': transitionOffsetTop.current,
        }}
      >
        {/* `Component` is an instance of `<Content>` */}
        {contentList.map(({ children, className, Component, ariaLabledByNav, index, name }) => {
          return (
            <Component
              className={className}
              ariaLabledByNav={ariaLabledByNav}
              idPrefix={id}
              index={index}
              isActive={activeIndex === index}
              isNavShown={showNav}
              isNext={nextIndex === index}
              key={index}
              name={name}
            >
              {children}
            </Component>
          )
        })}
      </ul>
    </>
  );
};

ContentSwitcher.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  navElementProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),
  unmountOnTransitionEnd: PropTypes.bool,
};

ContentSwitcher.Content = Content;
ContentSwitcher.Content.displayName = 'Content';

export default ContentSwitcher;
