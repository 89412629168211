/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import TrendingPosts from './TrendingPosts';
import MomentOfTheDay from './MomentOfTheDay';
import ShoppingPosts from './ShoppingPosts';
import FunModule from '../FunModule';
import styles from './splash.module.scss';

export const Splash = ({
  isTrackable = false,
  trackingData = {},
  zones = {},
  hasSponsoredTabEnabled = false,
}) => {
  const splashTrackingData = {
    ...trackingData,
    unit_name: 'splash',
    unit_type: 'feed',
  };

  return (
    <header className={`feed-content-area ${styles.splash}`}>
      <TrendingPosts
        data={zones.splash_trending}
        className={styles.trendingPosts}
        hasSponsoredTabEnabled={hasSponsoredTabEnabled}
        trackingData={{ position_in_unit: 0, ...splashTrackingData }}
      />
      <MomentOfTheDay
        item={zones.splash_moment?.items?.[0] || {}}
        className={styles.momentOfTheDay}
        trackingData={{ position_in_unit: 1, ...splashTrackingData }}
      />
      <ShoppingPosts
        data={zones.splash_shopping}
        className={styles.shoppingPosts}
        trackingData={{ position_in_unit: 2, ...splashTrackingData }}
      />
      <FunModule
        className={styles.funModule}
        items={zones.splash_fun?.items || []}
        isTrackable={isTrackable}
        trackingData={{ position_in_unit: 3, ...splashTrackingData }}
      />
    </header>
  );
};

Splash.propTypes = {
  zones: PropTypes.object.isRequired,
};

export default Splash;
