import { useEffect, useState, useMemo, useContext } from 'react';
import { useMediaQuery } from '../../../hooks';
import FeedTabs from '../../FeedTabs';
import useTabsWithLatestABTests from './useTabsWithLatestABTests';
import { Sparkles } from '../../../icons/Sparkles';
import { Fire } from '../../../icons/Fire';

const tabConfig = {
  tab_sponsored:{
    buttonLabel: (<><Sparkles/> <span>Sponsored</span></>),
    headline: ''
  },
  tab_latest: {
    buttonLabel: (<><Fire/> <span>Latest</span></>),
    headline: (<><Fire/> <span>Catch Up On The Latest</span></>)
  },
};

const tabOrder = ['tab_latest', 'tab_sponsored'];

const HomeFeedTabs = ({ abTestZones, tabZones, sponsorship, startAdIndexMobile = 1 }) => {
  const { breakpoint } = useMediaQuery();
  const [ adsIndex, setAdsIndex ] = useState(startAdIndexMobile);
  const hasSponsoredTab = !!tabZones?.tab_sponsored?.items?.length && sponsorship?.data?.tab?.enabled;

  const orderMap = new Map();
  tabOrder.forEach((item, index) => orderMap.set(item, index));
  const tabs = useMemo(() => (
    Object.entries(tabZones)
      .map(([name, props], index) => {
        if (name === 'tab_sponsored' && !hasSponsoredTab || tabOrder.indexOf(name) === -1) {
          return;
        }

        const tab = {
          ...props,
          headline: tabConfig[name]?.headline || '',
          label: tabConfig[name]?.buttonLabel || '',
        }

        if (name === 'tab_sponsored') {
          tab.className = 'isSponsored';
          const sponsoredTabName = sponsorship?.data?.tab?.tab?.text || 'Sponsored';
          const sponsoredTabIcon = !!sponsorship?.data?.tab?.cta?.icon?.length ?
            <img src={sponsorship.data.tab.cta.icon} alt={sponsoredTabName} width={14} aria-hidden="true"/>
            : <Sparkles/>;
          tab.label = <>
            {sponsoredTabIcon}
            <span>{sponsoredTabName}</span>
          </>;
          tab.headline = <>{sponsoredTabIcon} <span>{sponsoredTabName}</span></>;
        }
        return tab;
      })
      /**
       * Omit sponsored if not enabled
       */
      .filter(tab => !!tab?.items?.length).sort((a, b) => { // must always include a non empty items array
        const indexA = orderMap.get(a.name);
        const indexB = orderMap.get(b.name);
        return indexA - indexB;
      })
  ), [tabZones, sponsorship]);

  const renderedTabs = useTabsWithLatestABTests({
    tabs,
    abTestZones
  });

  useEffect(() => {
    if (!breakpoint) return;

    if (breakpoint === 'xs' || breakpoint === 'sm') {
      setAdsIndex(startAdIndexMobile);
    } else {
      setAdsIndex(1);
    }

  }, [breakpoint]);

  // do not render until after experiments fetch to avoid accidental extra impressions
  if (!renderedTabs) {
    return null;
  }
  return (
    <FeedTabs tabs={renderedTabs} adsStartIndex={adsIndex} />
  );
};

export default HomeFeedTabs;
