/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import ShoppingPost from './ShoppingPost/index'
import styles from './shoppingPosts.module.scss';

export const ShoppingPosts = ({ className = '', data = {}, trackingData = {} }) => {
  if (!data?.items?.length) {
    return null;
  }
  return (
    <section id="shopping-posts" className={`${className} ${styles['shoppingPosts']}`}>
      <h2>Shopping</h2>
      <>
        {data?.items?.map((item, index) => {
          return (
            <ShoppingPost
              item={item}
              index={index}
              key={item.id}
              trackingData={{ subunit_type: 'package', subunit_name: 'shopping', ...trackingData}}
            />);
          }
        )}
      </>
    </section>
  );
};

ShoppingPosts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default ShoppingPosts;
