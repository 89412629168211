import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './ContentSwitcher.module.scss';
import { useTrackingContext } from '../../hooks';

/**
 * Component facilitates the rendering and navigation of a dynamic list of child components with
 * optional CSS transitions. It renders the first child component along with a navigation element
 * for switching between components.
 * @component
 * @param {number} props.activeIndex - The index of the currently active content.
 * @param {string} [props.className=''] - Additional CSS class for styling the navigation element.
 * @param {Array} props.contentList - An array containing configuration for each child component.
 * @param {string} [props.idPrefix=''] - The unique identifier prefix used for defining all element
 * ID's in the ContentSwitcher component.
 * @param {number} [props.nextIndex] - The index of the next content to be transitioned to.
 * @param {function} [props.onClickCallback] - Callback function triggered on navigation click.
 * @param {object} [props.navRef] - A useRef object to reference the `<nav>` element for use in parent component.
 * @param {object} [props.style] - CSS properties as an object, placed on to the `<nav>` element.
 */
const Nav = ({
  activeIndex,
  className = '',
  contentList = [],
  idPrefix = '',
  isContentIntersecting,
  nextIndex,
  onClickCallback,
  navRef,
  style = {},
}) => {
  const tabFocusIndex = useRef(0);
  const { trackContentAction } = useTrackingContext();

  /**
   * Support assistive devices, enable keyboard arrow navigation between tabs in the tab list.
   */
  const handleKeydown = (event) => {
    let index = tabFocusIndex.current;

    if (['ArrowRight', 'ArrowLeft'].includes(event.key)) { // Move right
      event.preventDefault();

      if (event.key === 'ArrowRight') {
        index++;
        // If we're at the end, go to the start
        if (index >= contentList.length) {
          index = 0;
        }
      } else if (event.key === 'ArrowLeft') { // Move left
        index--;
        // If we're at the start, move to the end
        if (index < 0) {
          index = contentList.length - 1;
        }
      }

      const button = event.currentTarget.children[index]?.querySelector('button');
      if (button) {
        tabFocusIndex.current = index;
        button.focus();
      }
    }
  };

  const handleNavClick = useCallback((event) => {
    const index = Number(event.currentTarget?.dataset?.contentIndex);

    /**
     * Callback function will trigger everytime a tab is clicked. The callback has the freedom to trigger
     * additional tracking or other custom logic like, for example, scrolling back to the content
     * area on click.
     */
    onClickCallback(index);

    if (
      typeof index === 'number' &&
      index !== activeIndex
    ) {
      trackContentAction({
        ...contentList[index]?.navTrackingData,
        unit_name: contentList[activeIndex]?.navTrackingData?.unit_name,
      });
    }
  }, [activeIndex, onClickCallback]);

  return (
    <nav
      className={`${styles.nav} ${className}`}
      data-content-transition-state={
        typeof activeIndex === 'number' && typeof nextIndex === 'number' ? 'pending' : ''
      }
      ref={navRef}
      role="tablist"
      style={style}
    >
      <ul onKeyDown={handleKeydown}>
        {contentList.map(({ label, index, children }) => {
          const status =
            // Set active state if the index matches the activeIndex and the content is intersecting
            // the viewport (isContentIntersecting).
            (activeIndex === index && isContentIntersecting && 'active') ||
            (nextIndex === index && 'next') ||
            '';
          return (
            <li key={index}>
              <button
                aria-controls={`${idPrefix}-content-${index}`}
                aria-selected={status === 'active' ? 'true' : 'false'}
                data-content-index={index}
                data-content-nav-state={status}
                data-content-nav-name={children?.props?.data?.name}
                id={`${idPrefix}-nav-${index}`}
                onClick={handleNavClick}
                role="tab"
                tabIndex={status === 'active' ? '0' : '-1'}
              >{label}</button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

Nav.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  contentList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node, // JSX node
        PropTypes.elementType // React component type
      ]).isRequired,
      index: PropTypes.number.isRequired,
    })
  ).isRequired,
  idPrefix: PropTypes.string,
  nextIndex: PropTypes.number,
  onClickCallback: PropTypes.func,
  navRef: PropTypes.object,
  style: PropTypes.object,
};

export default Nav;
