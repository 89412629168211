export const Sparkles = ({ className = '' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 6.5c2.5-1 4-3.1 5-5.5 1 2.4 2.5 4.5 5 5.5-2.5 1-4 3.1-5 5.5a9.8 9.8 0 0 0-5-5.5ZM14 4c1-.6 1.6-1.7 2-3 .4 1.3 1 2.4 2 3-1 .6-1.6 1.7-2 3-.4-1.3-1-2.4-2-3ZM10 13.5c1.4-.7 2.4-2 3-3.5a6.1 6.1 0 0 0 3 3.5c-1.4.7-2.4 2-3 3.5a6.1 6.1 0 0 0-3-3.5Z"
      fill="#5246F5"/>
  </svg>
);
