import LinkWithTracking from '../../LinkWithTracking';
import { useViewImpression } from '../../../hooks';
import styles from '../promoBar.module.scss';

/**
 * @todo
 * Add functionality to handle the case when the user is not logged in.
 */

const ManualPromoBar = ({ className = '', title, description, cta, trackingData }) => {
  const trackingButtonData = {
    ...trackingData,
    item_type: 'button',
    item_name: cta?.text,
    target_content_type: 'url',
    target_content_id: cta?.url,
  };
  const { setObservable } = useViewImpression({
    trackingData: trackingButtonData
  });

  const attachImpressionObserver = (el) => {
    setObservable(el);
  }

  if (!title?.length || !description?.length || !cta?.text?.length || !cta?.url?.length) {
    return null;
  }

  return (
    <aside className={`${className} ${styles.container}`} ref={obvEl => attachImpressionObserver(obvEl)}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.body}>{description}</p>
        <LinkWithTracking
          className={styles.button}
          href={cta.url}
          commonTrackingData={trackingButtonData}
        >{cta.text}</LinkWithTracking>
      </div>
    </aside>
  );
};

export default ManualPromoBar;
