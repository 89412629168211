/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from '@buzzfeed/bf-utils/lib/device';
import { isServer } from '@buzzfeed/react-components/lib/utils/isServer';
import { ChevronRight } from '../../../icons/ChevronRight';
import { TrendingSpinner } from '../../../icons/TrendingSpinner';
import SplashCard from '../../FeedCard/SplashCard';
import { AdInFeed } from '../../Ads/units/AdInFeed';
import LinkWithTracking from '../../LinkWithTracking';
import styles from './trendingPosts.module.scss';

const CTA_TRENDING_LINK = '/trending';

export const TrendingPosts = ({ className = '', data = { items: [] }, hasSponsoredTabEnabled, trackingData }) => {
  const [primaryItem, ...secondaryItems] = data.items;
  const showBottomAd = !isServer() && isMobile();

  const baseTrackingData = {
    subunit_name: 'trending',
    subunit_type: 'package',
    ...trackingData,
  };
  const ctaTrackingData = {
    ...trackingData,
    item_type: 'text',
    target_content_type: 'feed',
    target_content_id: 'trending',
  }

  if (!primaryItem?.content) {
    return null;
  }

  return (
    <section className={`${className}`}>
      <header className={styles.headline}>
        <h2>Trending Now</h2>
        <LinkWithTracking
          className={styles.cta}
          href={CTA_TRENDING_LINK}
          commonTrackingData={{
            ...ctaTrackingData,
            item_name: 'see_all',
          }}
        >
          See All
          <ChevronRight />
        </LinkWithTracking>
        <TrendingSpinner className={styles.spinner}/>
      </header>
      <ol className={styles.trendingPosts}>
        <li className={`${styles.primaryCard}`}>
          <SplashCard
            className='primaryCard'
            item={primaryItem}
            index={1}
            imageRatioTablet='1/1'
            imageRatioDesktop='1/1'
            isTrackable={true}
            trackingData={{ ...baseTrackingData, ...primaryItem?.content?.trackingData, position_in_subunit: 0 }}
          />
        </li>
        {secondaryItems.map((item, index) => (
          <li key={item.id} className={`${styles.secondaryCard}`}>
            <SplashCard
              item={item}
              index={index+2}
              isPrimary={false}
              imageRatioDesktop="1/1"
              isTrackable={true}
              trackingData={{ ...baseTrackingData, ...item?.content?.trackingData, position_in_subunit: index+1 }}
            />
          </li>
        ))}
      </ol>
      <LinkWithTracking
        className={styles.ctaBottom}
        href={CTA_TRENDING_LINK}
        commonTrackingData={{
          ...ctaTrackingData,
          item_name: 'see_all_trending',
          position_in_subunit: data.items.length,
        }}
      >
        See All Trending
        <ChevronRight />
      </LinkWithTracking>
      {showBottomAd && <AdInFeed type="story1" />}
    </section>
  );
};

TrendingPosts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default TrendingPosts;
