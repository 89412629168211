import React from 'react';
import PropTypes from 'prop-types';
import ManualPromoBar from './ManualPromoBar'
// import AutomaticPromoBar from './AutomaticPromoBar'

const PromoBarComponents = {
  manual: ManualPromoBar,
  // automatic: AutomaticPromoBar,
};

const PromoBar = ({ className = '', item, trackingData }) => {
  const { content, id: contentObjectId, object_type: objectType } = item;
  const { type, trackingData: algorithmTrackingData } = content;
  const subunitTrackingData = {
    subunit_type: 'component',
    subunit_name: `${objectType}|${contentObjectId}`,
    position_in_subunit: 0,
  };

  const sharedTrackingData = {
    ...algorithmTrackingData,
    ...trackingData,
    ...subunitTrackingData,
  };

  const Component = PromoBarComponents[type];
  if (!Component) {
    return null
  }
  return <Component className={className} {...content} trackingData={sharedTrackingData} />
};

PromoBar.propTypes = {
  className: PropTypes.string,
  trackingData: PropTypes.object,
  item: PropTypes.shape({
    content: PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      cta: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  })
};

export default PromoBar;
