import { useEffect } from 'react';
import { useMediaQuery } from '../../../hooks';
import AdUnit from '../AdUnit';

const AdSectionAwareness = ({
  position = 'top',
  startAdIndexMobile = 1,
  hasSponsoredTabEnabled = false
}) => {
  const { breakpoint, isMobile } = useMediaQuery();

  /**
   * The `isMobile` variable is initially rendered as false on all screen sizes during server-side
   * rendering. This can cause layout shifts on mobile devices. To prevent this, the mobile
   * awareness ad will render by default until the client-side JavaScript determines the correct
   * screen size (when the value of `breakpoint` is not empty).
   */
  const isMobileOrUnknown = !breakpoint || isMobile;
  const awarenessClassName = hasSponsoredTabEnabled ? 'Ad--top-awareness' : 'Ad--bottom-awareness';

  useEffect(() => {
    if (hasSponsoredTabEnabled && isMobileOrUnknown) {
      document.documentElement.style.setProperty('--fixed-elements-offset-bottom', '61px');
    }
  }, [hasSponsoredTabEnabled, isMobileOrUnknown]);

  if (isMobileOrUnknown && position === 'top') {
    return (
      <AdUnit type="awareness" className={awarenessClassName} />
    );
  } else if (position === 'body') {
    return (
      <AdUnit
        className="Ad--section-awareness"
        stickyWithinPlaceholder={true}
        type={isMobile ? `story${startAdIndexMobile}` : 'awareness'}
      />
    );
  } else {
    return null;
  }
};

export default AdSectionAwareness;
