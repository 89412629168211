import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { ABeagleContext } from '../../../contexts';

/**
 * Mapping of the experiment name to the variant target feeds
 * - always assume "control" is existing feed, and do not need to provide
   * "experiment_name": {
   *   "mappings": {
   *      "variant1": "ab_test_1", // name of first ab test zone,
   *       ...
   *    }
   * }
 */
const pageExperiments = {
  /**
   * ML homepage time spent variant test: https://buzzfeed.atlassian.net/browse/ML-3115
   */
  'ml-3115-home-feed-timespent-ranking': {
    mappings: {
      'home-feed-timespent-v2': 'ab_test_1',
      randomized: 'ab_test_2'
    }
  }
};

/**
 * When running an experiment switching content on latest tab,
 *  - tabs: always the main control data, pass directly from props
 *  - abTestZones: any populated ab test zones, pass directly from props
 */
const useTabsWithLatestABTests = ({
  tabs,
  abTestZones = {},
}) => {
  const { experiments, getExperimentValue } = useContext(ABeagleContext);
  const [renderedTabs, setRenderedTabs] = useState(!Object.keys(pageExperiments)?.length ? tabs : null);
  useEffect(() => {
    // A/B experiments have loaded (client side) from upstream
    if (experiments?.loaded &&  Object.keys(pageExperiments)?.length) {
      let updatedTabs = tabs;
      Object.keys(pageExperiments).forEach((experiment) => {
        const variant = getExperimentValue(experiment, {
          rejectErrors: false,
        });
        if (variant && variant !== 'control') {
          const mappedExperiment = pageExperiments[experiment];

          // if we don't have sponsored tab, we show only latest tab
          const contents = updatedTabs.find((tab) => tab.name === 'tab_latest');
          const sourceTab = mappedExperiment?.mappings?.[variant];
          const updatedContents = abTestZones?.[sourceTab];

          if (
            mappedExperiment
            && updatedContents
            && updatedContents?.items?.length
            && contents
          ) {
            contents.items = updatedContents.items;
            contents.next = updatedContents.next;
          }
        }
      });
      setRenderedTabs(updatedTabs);

    }
  }, [experiments?.loaded]);

  return renderedTabs;
}

useTabsWithLatestABTests.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      items: PropTypes.array,
      next: PropTypes.string,
    })
  ).isRequired,
  abTestZones: PropTypes.object.isRequired,
};

export default useTabsWithLatestABTests;
