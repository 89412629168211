/*eslint-disable camelcase*/
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ContentSwitcher from '../ContentSwitcher';
import { FeedContent } from '../FeedContent';
import { useMediaQuery } from '../../hooks';
import styles from './FeedTabs.module.scss';


/**
 * Representing tabbed feed content in a tablist with sticky navigation.
 *
 * @param {Number} [adsStartIndex] - Position of the first ad in the tab content. Default is 1.
 * @param {Array} [tabs] - Data for each tab, including label and content.
 */
export const FeedTabs = ({
  adsStartIndex = 1,
  tabs = [],
}) => {
  const { isMobile } = useMediaQuery();

  /**
   * Memoized array of tab content components.
   */
  const tabContents = useMemo(() => tabs.map(
    ({ label, headline, extraItems, ...data }, index) => {
      const tabName = data?.name?.replace('tab_', '') || '';
      const navTrackingData = {
        action_type: 'select',
        action_value: tabName,
        item_name: tabName,
        item_type: 'button',
        position_in_subunit: index,
        position_in_unit: 0,
        subunit_name: 'tabs',
        subunit_type: 'component',
        unit_name: tabName,
      };

      const updatedData = !isMobile && Array.isArray(extraItems) && extraItems.length ? {
        ...data,
        // Adjusting the start index label to account for the extra items witch we have already added
        // to splash trending items and hide for desktop.
        startIndexLabel: data.startIndexLabel - extraItems.length,
        items: [
          ...extraItems,
          ...data.items,
        ],
      } : data;

      return (
        <ContentSwitcher.Content
          ariaLabledByNav={!headline}
          key={index}
          label={label}
          navTrackingData={navTrackingData}
          name={tabName}
        >
          <FeedContent
            adsAfterInitialPositions={[5]}
            adsStartIndex={adsStartIndex}
            data={updatedData}
            headline={headline}
            isTrackable={true}
            maxItemsPerGrouping={5}
            showNumbering={true ? tabName === 'trending' : false}
            trackingData={{ unit_name: tabName, unit_type: 'feed' }}
          />
        </ContentSwitcher.Content>
      )
    }
  ), [tabs, adsStartIndex, isMobile]);

  return (
    <ContentSwitcher navElementProps={{ className: styles.nav }} unmountOnTransitionEnd={true}>
      {tabContents}
    </ContentSwitcher>
  );
};

FeedTabs.propTypes = {
  adsStartIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node, // JSX node
      PropTypes.elementType, // React component type
    ]).isRequired,
  })),
};

export default FeedTabs;
