import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FEED_CONTENT_TRANSITION_END } from '../../constants/feeds';
import styles from './ContentSwitcher.module.scss';

/**
 * Component representing a single content item within the ContentSwitcher.
 * @component
 * @param {string} [props.ariaLabledByNav=true] - If true, will use the value of the corresponding
 * nav button to label the content.
 * @param {string} [props.className=''] - Additional CSS class for styling the content.
 * @param {string} props.idPrefix - The unique identifier prefix included as part of any defined element ID.
 * @param {number} props.index - The index of the content item.
 * @param {boolean} props.isActive - Flag indicating whether the content is active.
 * @param {boolean} props.isNavShown - Flag indicating whether the navigation is shown.
 * @param {boolean} props.isNext - Flag indicating whether the component is in the 'next' state (waiting for an exit transition to resolve).
 */
const Content = ({
  children,
  className = '',
  ariaLabledByNav = true,
  idPrefix,
  index,
  isActive,
  isNavShown,
  isNext,
  name,
 }) => {
  const shouldRenderChildren = isActive || isNext;
  const prevShouldRenderChildrenRef = useRef(false);
  const classNameList = useMemo(() => (
    [
      className,
      styles.content,
      isActive && styles.active,
      isNext && styles.next,
    ].filter(Boolean).join(' ')
  ), [className, isActive, isNext]);

  const props = useMemo(() => ({
    ...(shouldRenderChildren ? {} : { hidden: true }),
    ...(
      isNavShown ? {
        // If the content has a headline, the content is labelled by the headline.
        'aria-labelledby': ariaLabledByNav ? `${idPrefix}-nav-${index}` : null,
        role: 'tabpanel',
        tabIndex: '0',
      } : {}

    ),
  }), [ariaLabledByNav, isNavShown, shouldRenderChildren, index, idPrefix]);

  /**
   * Dispatch a custom event when the content is no longer rendered.
   */
  useEffect(() => {
    if (prevShouldRenderChildrenRef.current && !shouldRenderChildren) {
      const transitionEndEvent = new CustomEvent(
        FEED_CONTENT_TRANSITION_END,
        { detail: { index, name: children.props.data.name }}
      );
      window.dispatchEvent(transitionEndEvent);
    }

    prevShouldRenderChildrenRef.current = shouldRenderChildren;
  }, [shouldRenderChildren]);

  /**
   * Add an additional prop to each child component, (isTransitionApplied), to allow for conditional
   * rendering of exit transitions.
   */
  const renderedContent = useMemo(() => {
    return shouldRenderChildren && React.Children.map(
      children, child => React.cloneElement(child, { isNext })
    );
  }, [isNext, shouldRenderChildren, children]);

  /**
   * The <li> item is always rendered.
   * The `{children}` (the content) is only rendered if it is the currently selected (active)
   * content, or if it's the next content and is waiting for an exit transition to resolve.
   */
  return (
    <li
      className={classNameList}
      data-content-transition-status={(isActive && 'active') || (isNext && 'next')}
      data-content-tab-name={name}
      id={`${idPrefix}-content-${index}`}
      {...props}
    >
      {renderedContent}
    </li>
  );
 };

 Content.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  idPrefix: PropTypes.string,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  isNavShown: PropTypes.bool,
  isNext: PropTypes.bool,
  name: PropTypes.string,
};

 export default Content;
